<script setup lang="ts">
import type TnwebPage from "~/server/usecases/content/definitions/TnwebPage";
import { useStore } from "vuex";
import { usePageStore } from "~/pinia/platform/page";
import { useUserStore } from "~/pinia/platform/user/user";

import layouts from "~/gizmo-layouts";
import GizmoFace from "~/components/platform/inline-editing/GizmoLink/GizmoFace.vue";

import abTest from "@telenornorgeinternal/tn-gizmo-abtest-plugin";
import capitalize from "~/helpers/formatting/capitalize";

import "../components/platform/inline-editing/inline-editing.scss";

const store = useStore();
const pageStore = usePageStore();
const userStore = useUserStore();

definePageMeta({
	name: "CMS-managed page",
	middleware: [
		"resolve-segment-middleware",
		"get-globals-middleware",
		"update-chat-middleware",
		(to) => {
			// No explicit index pages (#363)
			if (to.path.endsWith("index.page")) {
				return navigateTo(
					{
						path: to.path.replace("index.page", ""),
						query: to.query,
					},
					{ redirectCode: 301 },
				);
			}

			if (to.path.endsWith(".jsp")) {
				return navigateTo(
					{
						path: to.path.replace(".jsp", ".page"),
						query: to.query,
					},
					{ redirectCode: 301 },
				);
			}

			// If the URL ends without a slash or suffix, try to add a slash
			if (!to.path.endsWith("/") && !/.+\..+$/.test(to.path)) {
				return navigateTo({
					path: to.path + "/",
					query: to.query,
				});
			}
		},
	],
});

const route = useRoute();

const CONTENT_API = useRuntimeConfig().public.previewMode ? "preview" : "content";

const { data, error, status } = await useFetch<TnwebPage>(
	`/api/${CONTENT_API}/page/${encodeURIComponent(route.path)}`,
	{
		deep: useRuntimeConfig().public.previewMode,
		key: encodeURIComponent(route.path),
	},
);

const loadedPage = data;

if (!loadedPage.value) {
	console.error(`Page not found: ${route.path}. Status: ${status.value}`, error.value);
	throw createError({ statusCode: 404, message: "Page not found", fatal: true });
}

let redirectUrl = loadedPage.value?.metadata?.url;
if (redirectUrl) {
	if (Object.keys(route.query).length > 0) {
		if (redirectUrl.includes("?")) {
			redirectUrl += "&";
		} else {
			redirectUrl += "?";
		}
		redirectUrl += new URLSearchParams(route.query).toString();
	}
	await navigateTo(redirectUrl, { external: true, redirectCode: 301 });
}

let page = loadedPage.value;
const pendingAbTest = ref(false);

const abTestPage = abTest.getAbTestPage(page, useRoute()?.query?.ab, useRuntimeConfig().public.previewMode);

if (page?.abTestPages?.length > 0) {
	pendingAbTest.value = true;
}

onMounted(() => {
	if (abTestPage) {
		const eCommerceProduct = page?.eCommerceProduct;
		abTestPage.eCommerceProduct = eCommerceProduct;
		page = abTestPage;
	}
	pendingAbTest.value = false;
});

/*if (abTestPage?.gizmoAbTest?.testIndex > 0 && !useRoute()?.query?.ab) {
	await navigateTo({ query: { ab: abTestPage?.gizmoAbTest?.testIndex, ...useRoute().query } }, { external: true });
} else {
	if (abTestPage) page = abTestPage;
}*/

/*
This weird way of loading the page is here to ensure that the useRoute.query object is updated _before_ rendering
any components inside. Because believe it or not, Nuxt decides it's a good idea to first render the components with
the old state, and then updating the router object.
 */

if (import.meta.server) {
	await pageStore.setPage(page);
}

if (import.meta.client) {
	if (page.template.ref === "tweBoxWebApp") {
		await pageStore.setPage(page);
	} else {
		pageStore.prepareForNavigation(page.template);

		await callOnce(() => pageStore.setPage(page));
		// ^ This is to prevent a hydration error, because hydration is done and checked before the mounted hook
		onMounted(() => {
			pageStore.setPage(page);
		});
	}
}
/*
End of weird block
 */

const layoutRef = computed(() => {
	let lr = pageStore.page?.template?.ref || "default";
	if (lr.indexOf("/")) lr = lr.split("/").pop();

	if (lr === "article") {
		lr = "standardArticle";
	} else if (lr === "yngStripped") {
		lr = "stripped";
	}

	return lr;
});

const layout = computed(() => layouts[layoutRef.value]);

const shareTitle = computed(() => {
	return (
		store.state.ecommerce?.product?.title ||
		page?.metadata?.shareTitle ||
		page?.metadata?.title ||
		capitalize(page?.metadata?.pageAction, true)
	);
});
const shareDescription = computed(() => {
	return (
		store.state?.ecommerce?.product?.descriptionSummary ||
		page?.metadata?.shareDescription ||
		page?.metadata?.description
	);
});

const shareImage =
	(page?.metadata?.shareImage
		? `https://www.telenor.no${page?.metadata?.shareImage?.src}`
		: page?.metadata?.articleImage
			? `https://www.telenor.no${page?.metadata?.articleImage?.image_md?.src}`
			: `https://www.telenor.no/assets/share-fallback-optimized.png`
	).replace(/\?.+$/, "") + "?mimeType=original"; // Ensure LinkedIn compatibility
const canonicalUrl =
	page?.metadata?.canonical?.url || (route.path && `https://www.telenor.no${route.path.replace("index.page", "")}`);

useSeoMeta({
	ogUrl: canonicalUrl,
	ogType: "website",
	title: shareTitle,
	ogTitle: shareTitle,
	twitterTitle: shareTitle,
	description: shareDescription.value,
	ogDescription: shareDescription.value,
	twitterDescription: shareDescription.value,
	ogImage: shareImage,
	twitterImage: shareImage,
	twitterCard: "summary_large_image",
	twitterSite: "@TelenorNorge",
});

// Meta tags not supported by useServerSeoMeta
const customMetaTags = [
	// Bing Webmaster Tools
	{ name: "msvalidate.01", content: "42CCB661DC4D2CB5A08793A2FD750446" },
];

const linkTags = [
	{
		rel: "canonical",
		href: canonicalUrl,
	},
];

if (page?.metadata?.useFaviconTwe) {
	linkTags.push(
		{ rel: "icon", href: "/favicon/favicon-twe.ico", sizes: "16x16" },
		{ rel: "icon", href: "/favicon/favicon.svg", type: "image/svg+xml" },
		{ rel: "apple-touch-icon", href: "/favicon/apple-touch-icon-twe.png" },
	);
}

useHead({
	meta: customMetaTags.filter((tag) => !!tag.content),
	link: linkTags.filter((tag) => !!tag.href),
});

if (!userStore.attemptedLogin && !useRuntimeConfig().public.previewMode) {
	onMounted(async () => {
		await userStore.getOrSetUser();
	});
}

const HERO_COMPONENTS = [
	"hero-banner--normal",
	"hero-banner--video",
	"gateway-links-bar",
	"hero-banner--video-twe",
	"vev-component-no-spacing",
];

const handlePageColor = () => {
	let pageColor = "white";
	if (page?.metadata?.dark) {
		pageColor = "dark";
	} else if (
		[
			["/privat/internett/bestill/"].includes(useRoute().path),
			useRoute().path.startsWith("/mitt-telenor"),
			useRoute().path.includes("/forsikring"),
		].some((e) => e)
	) {
		pageColor = "gray";
	}
	return pageColor;
};

onMounted(() => {
	if (page?.metadata?.dark) {
		document.body.classList.add("dark-background");
	} else {
		document.body.classList.remove("dark-background");
	}
});

const pageColor = handlePageColor();

const sections = computed(() => pageStore.page.layouts);
const segmentedSections = computed(() => sections.value?.filter(useComponentSegmentation));

const breadcrumbsBelowHero = computed(() => {
	return HERO_COMPONENTS.includes(segmentedSections.value?.[0]?.columns?.[0]?.[0]?.template?.name);
});

const { $sendPageData } = useNuxtApp();

useChat();

onMounted(() => {
	$sendPageData(useRoute());
});
</script>

<template>
	<NuxtLayout>
		<div
			class="pending-ab-test"
			v-if="pendingAbTest"
		>
			<TnSpinner
				color="blue"
				size="l"
			></TnSpinner>
		</div>
		<div :class="['layoutOuterContainer dynamic-page', `bg-${pageColor}`]">
			<component
				:is="layout"
				v-if="layout"
				:key="page.publishUrl"
			>
				<div :class="{ 'color-theme--dark': sections?.[0]?.background === 'dark' }">
					<div
						class="content-container"
						v-if="!breadcrumbsBelowHero"
					>
						<LazyBreadcrumbs
							:dark="sections?.[0]?.background === 'dark' || pageColor === 'dark'"
							:metadata="page?.metadata"
							:page="page"
							:layouts="sections"
						/>
					</div>
				</div>
				<template
					v-for="(section, index) in segmentedSections"
					:key="`${section}-${index}`"
				>
					<TSection
						:layout="section"
						:index="index"
						:page-color="pageColor"
						:last="index === segmentedSections.length - 1"
					/>
				</template>

				<div
					class="content-container"
					v-if="page?.metadata?.coreLinks?.length > 0"
				>
					<LazyCoreLinks
						:links="page?.metadata.coreLinks"
						class="margin-bottom-xl"
					/>
				</div>
			</component>
		</div>
		<div
			class="developer-toolbar"
			v-if="!useNuxtApp().$config.public.DISABLE_DEVTOOLS && !store?.state?.isMobile"
		>
			<ClientOnly>
				<GizmoFace />
			</ClientOnly>
		</div>
		<link
			href="https://fonts.googleapis.com/icon?family=Material+Icons"
			rel="stylesheet"
			v-if="$config.public.previewMode"
		/>
	</NuxtLayout>
</template>

<style scoped lang="scss">
.layoutOuterContainer {
	min-height: 100vh;
}

.developer-toolbar {
	position: fixed;
	bottom: $spacing-m;
	left: $spacing-m;
	z-index: 10000;
	display: flex;
}

.pending-ab-test {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 912931293129;
	background: rgba(255, 255, 255, 0.8);
	backdrop-filter: blur(3px);
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
}
</style>
